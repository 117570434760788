const commaSeperator = (x) => {
  const twoDecimal = Math.round(x * 100) / 100;
  const parts = twoDecimal.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const removeCommaSeperator = (x) => {
  const fmt = x.toString().split(',');
  return fmt.join('');
};

export default {
  commaSeperator,
  removeCommaSeperator,
};
