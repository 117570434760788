import React, { useState } from 'react';
import LabeledText from './components/input/LabeledText';
import RoundOff from './utils/RoundOff';
import './App.scss';
import NumberFormatter from './utils/NumberFormatter';


const App = () => {
  const [rooftArea, setRooftArea] = useState('');
  const [transformerCapacity, setTransformerCapacity] = useState('');

  const roofCapacity = RoundOff(rooftArea / 70);
  const parsedTransformerCapacity = parseFloat(transformerCapacity);

  const recommendedCapacity = (roofCapacity && transformerCapacity)
    ? (roofCapacity > parsedTransformerCapacity
      ? RoundOff(parsedTransformerCapacity) || 0
      : RoundOff(RoundOff(rooftArea / 70)))
    : 0;

  const averageMonthlyGeneration = RoundOff(recommendedCapacity * 114);


  const monthlyEarningsB7 = RoundOff(averageMonthlyGeneration * 22 * 0.16);
  const monthlyEarningsA7 = RoundOff(averageMonthlyGeneration * 15.5 * 0.2);

  const annualEarningsB7 = RoundOff(monthlyEarningsB7 * 12);
  const annualEarningsA7 = RoundOff(monthlyEarningsA7 * 12);

  return (
    <div className="opexog-calculator-container">
      <h2 className="title">Opex Calculator - On Grid</h2>
      <div className="input-fields">
        <LabeledText
          value={rooftArea}
          onChange={setRooftArea}
          label="Roof Area (sqft)"
        />
        <LabeledText
          value={transformerCapacity}
          onChange={setTransformerCapacity}
          label="Transformer Capacity (KVA)"
        />
      </div>
      <h6>Recommended Capacity</h6>
      <h2 className="red">{NumberFormatter.commaSeperator(recommendedCapacity)} KVA</h2>
      {/* ---------------------- */}
      <h6>Monthly power generation</h6>
      {/* <p>(114 average monthly generation)</p> */}
      <h2>{NumberFormatter.commaSeperator(averageMonthlyGeneration)} KVA</h2>
      {/* ---------------------- */}
      <h6>Monthly Earnings</h6>
      <div className="input-fields">
        <div className="stats">
          <h4>{NumberFormatter.commaSeperator(monthlyEarningsB7)}</h4>
          <p>1st 7 years (LKR)</p>
        </div>
        <div className="stats">
          <h4>{NumberFormatter.commaSeperator(monthlyEarningsA7)}</h4>
          <p>After 7 years (LKR)</p>
        </div>
      </div>
      {/* ---------------------- */}
      <h6>Annual Earnings</h6>
      <div className="input-fields">
        <div className="stats">
          <h4>{NumberFormatter.commaSeperator(annualEarningsB7)}</h4>
          <p>1st 7 years (LKR)</p>
        </div>
        <div className="stats">
          <h4>{NumberFormatter.commaSeperator(annualEarningsA7)}</h4>
          <p>After 7 years (LKR)</p>
        </div>
      </div>

    </div>
  )
}

export default App
